// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          Words once in common use now sound archaic. And the names of the famous dead as well: Camillus, Caseo, Volesus, Dentasus... Scipio and Cato... Augustus... Hadrian and Antoninus, and...
        </p>
        <p>
          Everything fades so quickly, turns into legend, and soon oblivion covers it.
        </p>
        <p>
          And those are the ones who shone. The rest--"unknown-unasked-for" a minute after death. What is "eternal" fame? Emptiness.
        </p>
        <p>
          Then what should we work for?
        </p>
        <p>
          - Marcus Aurelius
        </p>
      </header>
      <iframe title="dj set" width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1790062276&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden",whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: 100}}><a href="https://soundcloud.com/djcharles5" title="dj charles" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>dj charles</a> · <a href="https://soundcloud.com/djcharles5/2xdggh-edit" title="2xdggh-edit" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>2xdggh-edit</a></div>
      <div className="App-content">
        <h2>June, 2024</h2>
        {/* <p>Josh, Amber, Robin linkedin posts about discord: https://www.linkedin.com/posts/joshualu_speedrun-is-looking-for-more-applicants-building-activity-7193625778855505922-r1Gw?utm_source=share&utm_medium=member_desktop, https://www.linkedin.com/posts/amber-atherton_discord-founders-earlystagestartups-activity-7171907431621509120-S7o0</p> */}
        <p>Clay.com raised a <a className="App-link" href="https://www.clay.com/blog/clay-raises-62m-to-turn-any-growth-idea-into-reality">$46M Series B</a> at a $500M valuation. Clay helped me achieve a 39% email to in-person meeting conversion rate when cold-connecting with CEOs and investors at Reboot, a gaming conference. A recent <a className="App-link" href="https://www.youtube.com/watch?v=XwUEzbqzVsw">Workshop</a> by Eric Nowoslawski has lots of good Clay tips.</p>
        <p>Two ways to strengthen co-founder resilience: 1. Have an emotional sync every week. In the early days of Airbnb, the founders had dinner together every week. 2: Make sure your goals are aligned. One way is to take this <a className="App-link" href="https://docs.google.com/document/d/1RPfkNnm9pXtPNHtZQybm74BE4AbONj7VSIJ6hPFcVeQ/edit">questionairre</a>, and align. My co-founder and I took this indepdendently and found ~98% alignment on our answers, differences only in magnitude of outcomes. </p>
        <p>While many software engineers know about Copilot, the AI coding assistant, they don't know about <a className="App-link" href="https://github.com/search?type=code">Github code search</a>. Code search is very useful to see snippets of code in context, across all github projects. Copilot is good to answer high level questions, and code search to find implementation examples.</p>
        <h2>May, 2024</h2>
        <p>Data & Defensibility: Most valuable Bay Area startups are defensible due to network effects: when the n+1'th person joins a startup's network, that person creates value for people already in that network. The data generated by a startup can create network effects: an excellent <a className="App-link" href="https://www.nfx.com/post/truth-about-data-network-effects">article</a> by NFX details.</p>
        <p>To give negative feedback to others in high trust relationships, here's a start: after an offending situation, in private, share 1. the triggering action (an observable fact) and 2. the resulting emotion you experienced (another fact). Do not speak about non-facts: assumptions, inferences, or intentions. Sharing politely, immediately, and in a lightweight manner keeps relationships resilient. This tactic is from <a className="App-link" href="https://www.amazon.com/Resilience-Factor-Finding-Strength-Overcoming/dp/0767911911/">Resilience Factor</a>.</p>
        <p>The House of Arras has the best <a className="App-link" href="https://houseofarras.com.au/collections/our-wines/products/house-of-arras-brut-elite?variant=47093244166464">champagne</a>.</p>
        <h2>April, 2024</h2>
        <p>In an <a className="App-link" href="https://a16z.simplecast.com/episodes/how-discord-became-a-developer-platform-nrnar3ld-dnY2uobj">interview with Discord's CEO</a>, we learned in the 3 weeks post launch, developers built over 20,000 new Discord Activities, generating over 4 billion minutes of user interactions per day.</p>
        <p>Dr. Robert Yang, world-renowned for his research on deep neural network models of the brain, shut down his lab @ MIT to build digital humans. His company, <a className="App-link" href="https://altera.al/">Altera</a>, raised $9,000,000 and is building Minecraft NPCs to start. As of today, the bots run rogue and don't wait around for human players.</p>
        <p>To make the most of conferences/meetups, try <a className="App-link" href="https://www.clay.com/">Clay.com</a>. Copy the names of speakers/attendees from the event page into a Clay spreadsheet. Clay adds their LinkedIn experience and email addresses into spreadsheet columns. Export this to google sheets and use keyword searches to find the most relevant people. Email them 10 days before the event. Share a sentence about yourself, a sentence on how they might specifically help you, and then offer to buy them a coffee. More tips <a className="App-link" href="https://x.com/avichal/status/1048783856197763072?s=46">here</a>.</p>
        <p>Want to make a lightning fast website? Host with <a className="App-link" href="https://pages.cloudflare.com/">Cloudflare Pages</a>, and use <a className="App-link" href="https://developers.cloudflare.com/workers/wrangler/install-and-update/">Wrangler</a> to build it. <a className="App-link" href="https://dziurzyn.ski/">dziurzyn.ski</a> was built using Cloudflare Pages/Wrangler and has a perfect (100/100) performance score, according to <a className="App-link" href="https://developer.chrome.com/docs/lighthouse/overview">Lighthouse</a>. Want a similarly fast search experience? Use <a className="App-link" href="https://typesense.org/">Typesense</a>. <a className="App-link" href="https://finddiscordserver.com/">Find Discord Server</a> uses Pages and Typesense, with 97/100.</p>
        <h2>March, 2024</h2>
        <p>Discord launched its <a className="App-link" href="https://github.com/discord/embedded-app-sdk">Embedded Activities SDK</a>, which lets people develop web apps that people can access within a discord video call. Although Discord is traditionally the social app for gamers (1.5 Billion hours across 60k titles per month), activities is beyond games. For example, the most popular Discord activity lets people watch YouTube together.
        </p>
        <p>One of the best gaming companies emerged from stealth: <a className="App-link" href="https://www.agentic.ai/">Agentic AI</a>. Agentic solves multiplayer testing/QA for game studios by creating AI agents that only have access to the input controls. Multiplayer testing is currently very painful/impossible for many studios. As a result of this, Agentic will be able to offer best-in-class NPCs as a service, not to mention non-gaming applications. This is exactly the company I would be building if we had decided to build SaaS for the gaming industry.</p>
        <p>Looking for a lightweight puzzle game? <a className="App-link" href="https://echochess.com/">Echo Chess</a>, the wordle equivalent for chess, launched its daily challenges. Don't need to be a chess master to enjoy.</p>
        <p>Also for anyone passing through San Francisco: <a className="App-link" href="https://arsicault-bakery.com/"> Arsicault</a> has the best almond croissants, and the baked goods in SF rival the best of any city.</p>
      </div>
      <footer className="App-footer">
        <h2>Contact</h2>
        <a className="App-link" href="https://www.linkedin.com/in/lukedz/">LinkedIn</a>
      </footer>
    </div>
  );
}

export default App;
